import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Visibility, VisibilityOff, Close, CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  valid: {
    color: 'green',
  },
  invalid: {
    color: 'red',
  },
}));

const DialogPassword = ({ open, toggleOpen, handleSubmitPassword, isExpired }) => {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (open) {
      setOldPassword('');
      setNewPassword('');
      setRepeatNewPassword('');
      setShowOldPassword(false);
      setShowNewPassword(false);
      setShowRepeatNewPassword(false);
      setError('');
    }
  }, [open]);


  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowRepeatNewPassword = () => setShowRepeatNewPassword(!showRepeatNewPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+]/.test(password);
    return { minLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar };
  };

  const handleSubmit = () => {
    handleSubmitPassword(oldPassword, newPassword);
  };

  const passwordValidation = validatePassword(newPassword);
  const isPasswordValid = Object.values(passwordValidation).every(Boolean) && newPassword === repeatNewPassword && newPassword !== oldPassword;

  return (
    <Dialog open={open} onClose={isExpired ? undefined : toggleOpen} fullWidth maxWidth="sm">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Cambia Password
          </Typography>
          {!isExpired &&<IconButton edge="end" color="inherit" onClick={toggleOpen} aria-label="close">
            <Close />
          </IconButton>}
        </Toolbar>
      </AppBar>
      <DialogContent>
        {isExpired && (
        <Typography color="error">
          Password scaduta: aggiorna la password per continuare.
        </Typography>
        )}
        <Typography variant="body2" color="textSecondary" gutterBottom>
          La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale fra !@#$%^&*()_+
        </Typography>
        <TextField
          label="Vecchia Password"
          type={showOldPassword ? 'text' : 'password'}
          fullWidth
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Nuova Password"
          type={showNewPassword ? 'text' : 'password'}
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Ripeti Nuova Password"
          type={showRepeatNewPassword ? 'text' : 'password'}
          fullWidth
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showRepeatNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error && <Typography color="error">{error}</Typography>}
        <List>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {passwordValidation.minLength ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Almeno 8 caratteri" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {passwordValidation.hasUpperCase ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Almeno una lettera maiuscola" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {passwordValidation.hasLowerCase ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Almeno una lettera minuscola" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {passwordValidation.hasNumber ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Almeno un numero" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {passwordValidation.hasSpecialChar ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Almeno un carattere speciale" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {newPassword === repeatNewPassword ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="Le password coincidono" />
          </ListItem>
          <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemIcon>
              {newPassword !== oldPassword ? <CheckCircle className={classes.valid} /> : <Cancel className={classes.invalid} />}
            </ListItemIcon>
            <ListItemText primary="La nuova password è diversa dalla vecchia" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        {!isExpired && <Button onClick={toggleOpen} color="secondary" variant="contained" className={classes.button}>
          Annulla
        </Button>}
        <Button onClick={handleSubmit} color="primary" variant="contained" className={classes.button} disabled={!isPasswordValid}>
          Cambia Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPassword;